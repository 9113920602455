import imgDedicate from '@assets/images/img-dedicate-100.png'
import imgFeedback from '@assets/images/img-feedback.png'
import imgUser from '@assets/images/img-handle-all-optimize-experience.png'
import imgIntuitive from '@assets/images/img-intuitive-interface.png'

const content = [
    {
        id: 1,
        header: "Gérez tout depuis l'application mobile",
        title: 'Consultez et modifiez votre planning en 1 clic !',
        text: "L'application pour smartphone Staffea est conçue pour vous faciliter la vie. Plus besoin de demander un rendez-vous à votre hiérarchie : tout est accessible via l’app ! Sollicitez un échange, une modification d’horaires ou des congés en un clic. Transmettez vos arrêts maladies et signez électroniquement votre contrat de travail ou d'intérim.",
        image: imgUser,
        alt: 'Staffea, application mobile multifonctionnelle',
    },
    {
        id: 2,
        header: 'Accédez à une interface intuitive et ludique',
        title: 'Facilement utilisable par tous !',
        text: "Staffea possède une interface ergonomique, sécurisée et accessible n'importe où et n'importe quand, depuis votre smartphone. Très intuitive, vous n'aurez aucune difficulté à la prendre en main. Recevez des notifications push après chaque changement de planning. L'appli deviendra rapidement un outil indispensable à votre quotidien d'infirmier !",
        image: imgIntuitive,
        alt: 'Staffea, application mobile écrans de gestion des planning et disponibilités',
    },
    {
        id: 3,
        header: 'Consacrez-vous à 100% à votre métier',
        title: 'Vous êtes infirmier, pas gestionnaire de planning.',
        text: "Ne perdez plus de temps à négocier vos horaires ou vos congés. Sollicitez des modifications sur votre planning d'IDE quand vous en avez besoin. Votre manager vous fera un retour dans les plus brefs délais, directement via l'application. La gestion administrative n'a jamais été aussi simple !",
        image: imgDedicate,
        alt: 'Staffea, application mobile écrans de demande de congés et échange entre employés',
    },
    {
        id: 4,
        header: 'Transmettez directement vos feedbacks',
        title: 'Faites part de votre ressenti !',
        text: 'Après une mission ou une semaine de travail, partagez votre expérience à votre hiérarchie grâce au système de feedback sur Staffea. Faites entendre votre voix et remontez les éventuels événements indésirables qui peuvent se produire durant votre travail. Avec notre solution, vous êtes entendu par votre Ehpad.',
        image: imgFeedback,
        alt: "Staffea, application mobile écran d'édition de journée",
    },
]

export default content
